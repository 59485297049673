import { Component, EventEmitter, Output } from '@angular/core';
import { MediaGalleryView, MediaImage, MediaVideo } from '@services/media-services/models';
import { MediaGalleryService } from '@services/media-services/media-gallery.service';
import { MediaGalleryEntriesComponent } from '@enginuity/media/organisms/media-gallery-entries/media-gallery-entries.component';
import { MediaGalleryEntryComponent } from '@enginuity/media/organisms/media-gallery-entry/media-gallery-entry.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { MediaUploaderComponent } from '@enginuity/product/organisms/media/media-uploader/media-uploader.component';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';
import { NotifyService } from '@services/core-services/notify.service';

@Component({
  selector: 'app-media-gallery',
  standalone: true,
  imports: [
    MediaGalleryEntriesComponent,
    MediaGalleryEntryComponent,
    AsyncPipe,
    MediaUploaderComponent,
    LinkBtnComponent,
  ],
  templateUrl: './media-gallery.component.html',
  styleUrl: './media-gallery.component.scss',
})
export class MediaGalleryComponent {
  protected MediaGalleryView = MediaGalleryView;
  protected view: MediaGalleryView = MediaGalleryView.List;

  protected selectedEntry$;
  @Output() onClose = new EventEmitter();

  constructor(
    private readonly notify: NotifyService,
    private readonly mediaGalleryService: MediaGalleryService
  ) {
    this.selectedEntry$ = this.mediaGalleryService.getSelectedEntry();
    this.mediaGalleryService
      .getView()
      .pipe(
        takeUntilDestroyed(),
        tap(view => (this.view = view))
      )
      .subscribe();
  }

  onEntryClose() {
    this.mediaGalleryService.selectEntry(undefined);
  }

  onEntryDelete() {
    this.mediaGalleryService.selectEntry(undefined);
  }

  handleImagesUpload(images: any[]) {
    this.mediaGalleryService.addEntries(images as (MediaImage | MediaVideo)[]);
    this.notify.success('Upload successfully completed.');
  }

  handleVideosUpload(videos: any[]) {
    this.mediaGalleryService.addEntries(videos as (MediaImage | MediaVideo)[]);
    this.notify.success('Upload successfully completed.');
  }

  onUploadBack() {
    this.mediaGalleryService.setView(MediaGalleryView.List);
  }
}
