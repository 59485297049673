<div class="media-gallery">
  <div class="media-gallery-header">
    <h3 class="h3-bold primary-100">Choose from Media Area</h3>

    <app-badges
      [withIcon]="false"
      Type="tag"
      IconType="add"
      [label]="((pagination$ | async)?.total_records || 0).toString()"
      [ellipsis]="true"></app-badges>

    <div class="spacing"></div>
    <!-- Switch Grid/Table -->
    <app-box-tab
      class="box-tab"
      size="large"
      (selectedTab)="switchDisplay($event)"
      [boxContent]="(displayOptions$ | async) || []"></app-box-tab>

    <div class="spacing"></div>

    <!-- TODO: Enable sorting when supported -->
    <!--<app-dropdown-btn
      [disabled]="false"
      [selecteditems]="''"
      [active]="false"
      [statusType]="false"
      [status]="'Recently created'"
      [dropdownStatus]="false"
      [showDropdown]="true"
      [dropdownList]="true"
      [dropdown]="sortByOptions$ | async"
      [size]="'large'"></app-dropdown-btn>-->

    <!-- Upload Button -->
    <app-buttons
      [State]="'default'"
      [active]="false"
      [success]="false"
      [shortcutKey]="false"
      [IconType]="'no_icon'"
      [alert]="false"
      [disabled]="false"
      [label]="'Upload new assets'"
      [size]="'large'"
      (click)="activateUpload()"></app-buttons>

    <app-action-btn
      (custom_click)="onClose.emit()"
      [active]="false"
      IconType="cancel"></app-action-btn>
  </div>
  <div class="media-gallery-main">
    <div class="media-gallery-folders">
      <!-- Folder Tree -->
      <app-media-gallery-folders
        [folders]="folders$ | async"
        [selection]="selectedFolder$ | async"
        (onAddRequest)="onFolderAddRequest()"
        (onDeleteRequest)="onFolderDeleteRequest($event)"
        (onRenameRequest)="onFolderRenameRequest($event)"
        (onSelect)="onFolderSelect($event)"></app-media-gallery-folders>
    </div>
    <div class="media-gallery-content" *ngIf="display$ | async as display">
      <!-- Filter -->
      <div class="media-gallery-content-filter">
        <app-filter
          [setup]="filterSetup$ | async"
          (onSearch)="onSearch($event)"
          (onChange)="onFilterChange($event)"></app-filter>
      </div>
      <div class="media-gallery-content-entries custom-scroll">
        <div class="media-gallery-content-entries-inner">
          <!-- Entries : No Data -->
          <app-no-data *ngIf="!(loading$ | async) && !(entries$ | async)?.length"></app-no-data>
          <!-- Entries : Grid -->
          <div class="ce-row custom-scroll gallery-overflow" *ngIf="display?.id === 'grid'">
            <app-media-item-thumbnail
              class="media-gallery-grid-thumbnail ce-col-lg-3 ce-col-md-3 ce-col-sm-6 ce-col-12"
              *ngFor="let entry of entries$ | async"
              [entry]="entry"
              (onSelect)="onEntrySelect($event)"
              (onCheck)="onEntryCheck($event)"
              (onUncheck)="onEntryUncheck($event)"></app-media-item-thumbnail>
          </div>

          <!-- Entries : Table -->
          <div class="media-gallery-table" *ngIf="display?.id === 'list'">
            <app-table-view
              (onClick)="onTableClick($event)"
              [loader]="mediaLoader$ | async"
              [errorMessage]="errorMessage$ | async"
              [table_schema]="(table$ | async)!"></app-table-view>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="media-gallery-content-footer">
        <app-list-footer
          [selection]="selection$ | async"
          [pagination]="pagination$ | async"
          [actions]="footerActions$ | async"
          (onPageChange)="onPageChange($event)"
          (onAction)="onFooterAction($event)">
        </app-list-footer>
      </div>
    </div>
  </div>
</div>
