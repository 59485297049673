import { Component, Input } from '@angular/core';
import { FormFieldComponent } from '@enginuity/campaign/molecules/form-field/form-field.component';
import { InputsComponent } from '@enginuity/core/molecules/inputs/inputs.component';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';
import { SecondaryBtnsComponent } from '@enginuity/core/molecules/secondary-btns/secondary-btns.component';
import { ModalService } from '@services/core-services/modal.service';
import { Folder } from '@services/media-services/models';
import { JsonPipe, NgIf } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MediaFolderTreeSelectComponent } from '@enginuity/media/organisms/media-folder-tree-select/media-folder-tree-select.component';

type Mode = 'rename' | 'add';

@Component({
  selector: 'app-media-folder-manage-form',
  standalone: true,
  imports: [
    FormFieldComponent,
    InputsComponent,
    ButtonsComponent,
    SecondaryBtnsComponent,
    JsonPipe,
    ReactiveFormsModule,
    NgIf,
    MediaFolderTreeSelectComponent,
  ],
  templateUrl: './media-folder-manage-form.component.html',
  styleUrl: './media-folder-manage-form.component.scss',
})
export class MediaFolderManageFormComponent {
  private _folder: Folder | undefined;

  get folder(): Folder | undefined {
    return this._folder;
  }

  @Input() set folder(value: Folder | undefined) {
    this.init(value);
    this._folder = value;
  }

  protected mode: Mode = 'add';
  protected form = this.fb.group({
    name: ['', Validators.required],
    parent_folder_id: '',
  });

  constructor(
    private readonly fb: FormBuilder,
    private readonly modal: ModalService
  ) {}

  init(folder: Folder | undefined) {
    this.mode = folder ? 'rename' : 'add';
    this.form.setValue({
      name: folder?.name || '',
      parent_folder_id: folder?.parent_folder_id || '',
    });
  }

  onSave() {
    this.modal.close({ ...this.folder, ...this.form.getRawValue() });
  }

  onCancelClick() {
    this.modal.close();
  }
}
