import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Folder, FolderNode, FolderViewMode } from '@services/media-services/models';
import { JsonPipe, NgIf } from '@angular/common';
import {
  CdkNestedTreeNode,
  CdkTree,
  CdkTreeNodeDef,
  CdkTreeNodeOutlet,
  CdkTreeNodeToggle,
  NestedTreeControl,
} from '@angular/cdk/tree';
import { ArrayDataSource } from '@angular/cdk/collections';
import { IconContainerComponent } from '@enginuity/core/atoms/icon-container/icon-container.component';
import { IconBtnComponent } from '@enginuity/core/molecules/icon-btn/icon-btn.component';
import { MediaService } from '@services/media-services/media.service';
import { BadgesComponent } from '@enginuity/core/molecules/badges/badges.component';
import { ActionBtnComponent } from '@enginuity/core/molecules/action-btn/action-btn.component';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { DropdownSinglePieceComponent } from '@enginuity/core/molecules/dropdown-single-piece/dropdown-single-piece.component';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';
import { MediaFolderActionsComponent } from '@enginuity/media/organisms/media-folder-actions/media-folder-actions.component';
import { Action } from '@services/core-services/models';
import { ActionEvent } from '@services/ui-services/models';

@Component({
  selector: 'app-media-gallery-folders',
  standalone: true,
  imports: [
    JsonPipe,
    CdkTree,
    CdkNestedTreeNode,
    CdkTreeNodeDef,
    CdkTreeNodeToggle,
    CdkTreeNodeOutlet,
    IconContainerComponent,
    IconBtnComponent,
    BadgesComponent,
    ActionBtnComponent,
    CdkMenu,
    CdkMenuItem,
    DropdownSinglePieceComponent,
    LinkBtnComponent,
    CdkMenuTrigger,
    MediaFolderActionsComponent,
    NgIf,
  ],
  templateUrl: './media-gallery-folders.component.html',
  styleUrl: './media-gallery-folders.component.scss',
})
export class MediaGalleryFoldersComponent implements OnChanges {
  @Input() folders: Folder[] | null = [];
  @Input() selection: FolderNode | undefined | null;
  @Input() mode: FolderViewMode = FolderViewMode.View;

  @Output() onSelect = new EventEmitter<FolderNode>();
  @Output() onAddRequest = new EventEmitter();
  @Output() onRenameRequest = new EventEmitter<Folder>();
  @Output() onDeleteRequest = new EventEmitter<Folder>();

  protected readonly FolderViewMode = FolderViewMode;
  protected hasChild = (_: number, node: FolderNode) => !!node.folders && node.folders.length > 0;
  protected treeControl = new NestedTreeControl<FolderNode>(node => node.folders);
  protected dataSource: ArrayDataSource<FolderNode> = new ArrayDataSource([]);

  constructor(private readonly mediaService: MediaService) {}

  ngOnChanges(changes: SimpleChanges) {
    const folders = changes['folders']?.currentValue;
    if (folders) {
      const nodes: FolderNode[] = this.mediaService.getFolderTree(folders);
      this.dataSource = new ArrayDataSource(nodes);
    }
  }

  selectFolder(e: any, node: FolderNode) {
    e.stopPropagation();
    this.onSelect.emit(node);
  }

  onEdit() {
    this.mode = this.mode === FolderViewMode.View ? FolderViewMode.Edit : FolderViewMode.View;
  }

  onAdd() {
    this.onAddRequest.emit();
  }

  onFolderAction(event: ActionEvent, node: FolderNode) {
    const { parent_folder_id, id, name } = node;
    if (event.key === 'rename') {
      this.onRenameRequest.emit({ parent_folder_id, id, name } as Folder);
    }
    if (event.key === 'delete') {
      this.onDeleteRequest.emit({ parent_folder_id, id, name } as Folder);
    }
  }
}
