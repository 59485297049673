import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MediaGalleryFoldersComponent } from '@enginuity/media/organisms/media-gallery-folders/media-gallery-folders.component';
import { BadgesComponent } from '@enginuity/core/molecules/badges/badges.component';
import { DropdownBtnComponent } from '@enginuity/core/molecules/dropdown-btn/dropdown-btn.component';
import { BoxTabComponent } from '@enginuity/core/molecules/box-tab/box-tab.component';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';
import { MediaGalleryService } from '@services/media-services/media-gallery.service';
import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';
import { Observable, of, Subscription, switchMap } from 'rxjs';
import {
  Folder,
  FolderNode,
  MediaGalleryView,
  MediaImage,
  MediaVideo,
} from '@services/media-services/models';
import { MediaItemThumbnailComponent } from '@enginuity/media/organisms/media-item-thumbnail/media-item-thumbnail.component';
import { ModalService } from '@services/core-services/modal.service';
import { MediaFolderManageFormComponent } from '@enginuity/media/organisms/media-folder-manage-form/media-folder-manage-form.component';
import { FilterComponent } from '@enginuity/core/organisms/filtering/filter/filter.component';
import { MediaFilterProvider } from '@services/media-services/media-filter.provider';
import { FilterRecord } from '@services/core-services/filters';
import { ListFooterComponent } from '@enginuity/core/organisms/list-footer/list-footer.component';
import { FooterAction, ProcessingState } from '@services/core-services/models';
import { ModalComponent } from '@enginuity/core/organisms/modal/modal.component';
import { MediaEntryMoveFormComponent } from '@enginuity/media/organisms/media-entry-move-form/media-entry-move-form.component';
import { NotifyService } from '@services/core-services/notify.service';
import { TableViewComponent } from '@enginuity/core/organisms/table-view/table-view.component';
import { ActionBtnComponent } from '@enginuity/core/molecules/action-btn/action-btn.component';
import { NoDataComponent } from '@enginuity/core/organisms/no-data/no-data.component';

@Component({
  selector: 'app-media-gallery-entries',
  standalone: true,
  imports: [
    MediaGalleryFoldersComponent,
    BadgesComponent,
    DropdownBtnComponent,
    BoxTabComponent,
    ButtonsComponent,
    AsyncPipe,
    MediaItemThumbnailComponent,
    NgForOf,
    FilterComponent,
    ListFooterComponent,
    ModalComponent,
    NgIf,
    TableViewComponent,
    JsonPipe,
    ActionBtnComponent,
    NoDataComponent,
  ],
  templateUrl: './media-gallery-entries.component.html',
  styleUrl: './media-gallery-entries.component.scss',
  providers: [MediaFilterProvider],
})
export class MediaGalleryEntriesComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public mediaLoader$ = new Observable<ProcessingState>();
  public errorMessage$: Observable<string>;

  @Output() onClose = new EventEmitter();

  protected displayOptions$;
  protected display$;
  protected sortByOptions$;
  protected folders$;
  protected selectedFolder$;
  protected loading$;
  protected entries$;
  protected table$;
  protected pagination$;
  protected footerActions$;
  protected filterSetup$;
  protected selection$;

  protected deleteGalleryEntriesModalConfig: any = {
    modalHeading: 'Delete gallery entries',
    modalDescription:
      'You are about to delete multiple gallery entries. Are you sure you want to proceed?',
    topIcon: 'question',
    showContent: true,
    showIcon: true,
    showBtns: true,
    showInput: true,
    search: false,
    primaryBtn: {
      label: 'Yes, proceed!',
      active: false,
      alert: false,
      disabled: false,
      continueBtn: true,
    },
    secondaryBtn: {
      label: 'Cancel',
      backBtn: true,
    },
  };

  constructor(
    private readonly modalService: ModalService,
    private readonly notify: NotifyService,
    private readonly mediaFilter: MediaFilterProvider,
    private readonly mediaGalleryService: MediaGalleryService
  ) {
    this.displayOptions$ = this.mediaGalleryService.getDisplayOptions();
    this.display$ = this.mediaGalleryService.getDisplay();
    this.sortByOptions$ = this.mediaGalleryService.getSortByOptions();
    this.folders$ = this.mediaGalleryService.getFolders();
    this.selectedFolder$ = this.mediaGalleryService.getSelectedFolder();
    this.loading$ = this.mediaGalleryService.getLoading();
    this.entries$ = this.mediaGalleryService.getEntries();
    this.table$ = this.mediaGalleryService.getTable();
    this.pagination$ = this.mediaGalleryService.getPagination();
    this.filterSetup$ = this.mediaGalleryService.getFilterSetup();
    this.selection$ = this.mediaGalleryService.getSelection();
    this.footerActions$ = this.mediaGalleryService.getFooterActions();
    this.errorMessage$ = this.mediaGalleryService.errorMessage.asObservable();
    this.mediaLoader$ = this.mediaGalleryService.getMediaLoader();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.mediaGalleryService.loadFolders().subscribe(),
      this.mediaGalleryService.loadEntries().subscribe(),
      this.mediaGalleryService.setupFilter(this.mediaFilter).subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.mediaGalleryService.clearSelection();
  }

  onFolderSelect(node: FolderNode) {
    this.mediaGalleryService.selectFolder(node);
    this.subscriptions.push(this.mediaGalleryService.loadEntries().subscribe());
  }

  switchDisplay(id: string) {
    this.mediaGalleryService.setDisplay(id);
  }

  activateUpload() {
    this.mediaGalleryService.setView(MediaGalleryView.Upload);
  }

  onFolderAddRequest() {
    this.modalService
      .open(MediaFolderManageFormComponent, {
        folder: undefined,
      })
      .pipe(switchMap(result => this.mediaGalleryService.createFolder(result)))
      .subscribe(result => {
        this.modalService.close(result);
      });
  }

  onFolderRenameRequest(folder: Folder) {
    this.modalService
      .open(MediaFolderManageFormComponent, {
        folder,
      })
      .pipe(switchMap(result => this.mediaGalleryService.updateFolder(folder.id!, result)))
      .subscribe(result => {
        this.modalService.close(result);
      });
  }

  onFolderDeleteRequest(folder: Folder) {
    this.modalService.open(MediaFolderManageFormComponent, {
      folder,
    });
  }

  onEntrySelect(entry: MediaImage | MediaVideo) {
    this.mediaGalleryService.selectEntry(entry);
  }

  onEntryCheck(entry: MediaImage | MediaVideo) {
    this.mediaGalleryService.checkEntry(entry, true);
  }

  onEntryUncheck(entry: MediaImage | MediaVideo) {
    this.mediaGalleryService.checkEntry(entry, false);
  }

  onSearch(search: string) {
    this.subscriptions.push(this.mediaGalleryService.applySearch(search).subscribe());
  }

  onFilterChange(records: FilterRecord[]) {
    this.subscriptions.push(
      this.mediaGalleryService.applyFilter({ filters: records.map(r => r.rule) }).subscribe()
    );
  }

  onPageChange(page: number) {
    this.subscriptions.push(this.mediaGalleryService.setPage(page).subscribe());
  }

  onFooterAction(action: FooterAction) {
    if (action.key === 'delete') {
      this.subscriptions.push(
        this.modalService
          .confirm(this.deleteGalleryEntriesModalConfig)
          .pipe(
            switchMap(result => {
              const { action } = result;
              if (action === 'confirm') {
                return this.mediaGalleryService.deleteCheckedEntries();
              }
              return of(result);
            })
          )
          .subscribe(() => this.notify.success('Media entries successfully deleted.'))
      );
    } else if (action.key === 'move') {
      this.subscriptions.push(
        this.modalService
          .open(MediaEntryMoveFormComponent)
          .pipe(switchMap(result => this.mediaGalleryService.moveCheckedEntries(result?.folder_id)))
          .subscribe(result => {
            this.notify.success('Media entries successfully moved.');
            this.modalService.close(result);
          })
      );
    }
  }

  onTableClick(entry: any) {
    const { action, value } = entry;
    if (action === 'open-detail') {
      this.mediaGalleryService.selectEntry(value);
    } else if (action === 'row-checkbox') {
      this.mediaGalleryService.checkEntry(entry.value.data, entry.value.isChecked);
    } else if (action === 'header-checkbox') {
      this.mediaGalleryService.checkEntry(undefined, value);
    }
  }
}
