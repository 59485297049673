import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  FilterGroupType,
  FilterProvider,
  FilterSetup,
  FilterType,
} from 'src/base/services/core-services/filters';
import { enumToSource } from '@services/core-services/filters/converters';
import { MediaItemFileType } from '@services/media-services/models';

@Injectable()
export class MediaFilterProvider implements FilterProvider {
  getSetup(): Observable<FilterSetup> {
    return of({
      entries: [
        {
          field: 'file_name',
          label: 'File Name',
          group: FilterGroupType.Data,
          type: FilterType.String,
          icon: 'balance',
        },
        {
          field: 'created_at',
          label: 'Date created',
          group: FilterGroupType.Data,
          type: FilterType.Date,
          icon: 'calendar',
        },
        {
          field: 'type',
          label: 'Type',
          icon: 'balance',
          group: FilterGroupType.Data,
          type: FilterType.Enum,
          source: () => enumToSource(MediaItemFileType),
        },
      ],
    });
  }
}
